<template>
    <div>
        <div class="panel panel-default">
            <div class="panel-heading">
                <label><input type="radio" v-model="state.optionFields.reportType.val" class="report_type" value="weekly"> Weekly Labor Rate</label>
            </div>
            <div class="row panel-body">
                <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Start Date</label>
                    <DatePicker
                            v-model="state.optionFields.weeklyStartDate.val"
                            :input-attributes="(state.optionFields.reportType.val === 'weekly') ? { class: 'form-control' } : { class: 'form-control', disabled: true }">
                    </DatePicker>
                </div>
            </div>
        </div>

        <div class="panel panel-default">
            <div class="panel-heading">
                <label><input type="radio" v-model="state.optionFields.reportType.val" class="report_type" value="timespan"> Timespan Labor Rate</label>
            </div>
            <div class="row panel-body">
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Start Date/Time</label>
                    <DatePicker
                            v-model="state.optionFields.startDate.val"
                            :pickTime="true" :format="'MM/DD/YYYY hh:mm'"
                            :input-attributes="(state.optionFields.reportType.val === 'timespan') ? { class: 'form-control' } : { class: 'form-control', disabled: true }">
                    </DatePicker>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>End Date/Time</label>
                    <DatePicker
                            v-model="state.optionFields.endDate.val"
                            :pickTime="true" :format="'MM/DD/YYYY hh:mm'"
                            :input-attributes="(state.optionFields.reportType.val === 'timespan') ? { class: 'form-control' } : { class: 'form-control', disabled: true }">
                    </DatePicker>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { store } from "@/store/Report.store.js";
    import DatePicker from "../utils/DatePicker";


    export default {
        name: 'Options',
        components: {DatePicker},
        data() {
            return {
                state: store.state,
            }
        }
    }
</script>