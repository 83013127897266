<template>
    <div>
        <h1>Labor Rate</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Results />
        </form>
    </div>
</template>
<script>
    import { store } from "@/store/Report.store.js";
    import Results from "./utils/Results";
    import Options from "./labor_opt";

    export default {
        name: 'labor',
        components: { Results, Options },
        data() {
            return {
                state: store.state,
                optionFields: {
                    weeklyStartDate: {val: new Date(new Date().setDate(new Date().getDate() - new Date().getDay())).toLocaleDateString(), qsField: '[start_date]'},
                    startDate: {val: `${new Date(new Date().setDate(new Date().getDate() - new Date().getDay())).toLocaleDateString()} 00:00`, qsField: '[start_datetime]'},
                    endDate: {val: `${new Date(new Date().setDate(new Date().getDate() - new Date().getDay())).toLocaleDateString()} 00:00`, qsField: '[end_datetime]'},
                    reportType: {val: 'weekly', qsField: '[report_type]'},
                },
                groupByFields: {},
                dataFields: {}
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Labor Rate']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>